import React, { useState } from "react";
import logo from "../assets/logo.svg";
import logo_en from "../assets/logo.png";
import Popup from "reactjs-popup";
import { useForm } from "react-hook-form";
import { send } from "emailjs-com";
import close from "../assets/close.svg";

const Main = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);

  const [isEng, setIsEng] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [sendSuccess, setSendSuccess] = useState(false);

  const sendFormData = async (data, error) => {
    send(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      data,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    );
    reset();
    setSendSuccess(true);
  };

  return (
    <section
      id="main"
      className="bg-top bg-no-repeat bg-mobile lg:bg-main bg-cover fullscreen"
    >
      <div className="p-8 relative overflow-hidden">
        <div className="flex flex-col lg:flex-row gap-y-8 lg:gap-y-0 justify-between lg:items-center">
          <div className="flex gap-x-4 items-center">
            <img
              className="w-36"
              src={isEng ? logo_en : logo}
              alt="Agrofood"
            ></img>
            <div className="w=[1px] h-8 border border-th-blue"></div>
            <p className="text-th-blue">
              {isEng ? "This site is under construction" : "Сайт в розробці"}
            </p>
            <div className="flex ml-auto lg:ml-0 lg:hidden gap-x-4 items-center cursor-pointer">
              <p
                onClick={() => setIsEng(false)}
                className={!isEng ? "text-th-blue/50" : "text-th-blue"}
              >
                UA
              </p>
              <div className="w=[1px] h-4 border border-th-blue"></div>
              <p
                onClick={() => setIsEng(true)}
                className={isEng ? "text-th-blue/50" : "text-th-blue"}
              >
                EN
              </p>
            </div>
          </div>
          <div className="md:flex gap-x-8 items-center">
            <div className="flex gap-x-4 items-center justify-center">
              <a
                href="https://www.instagram.com/agrifoodexchange/"
                target="_blank"
              >
                <svg
                  className="w-6"
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24 32C26.1217 32 28.1566 31.1571 29.6569 29.6569C31.1571 28.1566 32 26.1217 32 24C32 21.8783 31.1571 19.8434 29.6569 18.3431C28.1566 16.8429 26.1217 16 24 16C21.8783 16 19.8434 16.8429 18.3431 18.3431C16.8429 19.8434 16 21.8783 16 24C16 26.1217 16.8429 28.1566 18.3431 29.6569C19.8434 31.1571 21.8783 32 24 32Z"
                    stroke="#01418B"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6 32V16C6 13.3478 7.05357 10.8043 8.92893 8.92893C10.8043 7.05357 13.3478 6 16 6H32C34.6522 6 37.1957 7.05357 39.0711 8.92893C40.9464 10.8043 42 13.3478 42 16V32C42 34.6522 40.9464 37.1957 39.0711 39.0711C37.1957 40.9464 34.6522 42 32 42H16C13.3478 42 10.8043 40.9464 8.92893 39.0711C7.05357 37.1957 6 34.6522 6 32Z"
                    stroke="#01418B"
                    stroke-width="3"
                  />
                  <path
                    d="M35 13.02L35.02 12.998"
                    stroke="#01418B"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
              <a href="https://www.facebook.com/tsukrovabirzha" target="_blank">
                <svg
                  className="w-6"
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M44 24C44 12.96 35.04 4 24 4C12.96 4 4 12.96 4 24C4 33.68 10.88 41.74 20 43.6V30H16V24H20V19C20 15.14 23.14 12 27 12H32V18H28C26.9 18 26 18.9 26 20V24H32V30H26V43.9C36.1 42.9 44 34.38 44 24Z"
                    fill="#01418B"
                  />
                </svg>
              </a>
            </div>
            <div className="flex gap-x-4 items-center">
              <a href="tel:+380981386886">
                <p className="text-th-blue">+380 98 138 68 86</p>
              </a>
              <div className="w=[1px] h-4 border border-th-blue"></div>
              <a href="mailto:info@afex.com.ua">
                <p className="text-th-blue">info@afex.com.ua</p>
              </a>
            </div>
            <p className="text-th-blue">
              {isEng
                ? "10 Malopidvalna St, office 11, 01001, Kyiv, Ukraine"
                : "Україна, м. Київ, вул. Малопідвальна 10, офіс 11"}
            </p>
          </div>
          <div className="hidden lg:flex gap-x-4 items-center cursor-pointer">
            <p onClick={() => setIsEng(false)} className="text-th-blue/50">
              UA
            </p>
            <div className="w=[1px] h-4 border border-th-blue"></div>
            <p onClick={() => setIsEng(true)} className="text-th-blue">
              EN
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-y-8 mt-24 md:mt-48 max-w-5xl mx-auto items-center">
          <h1 className="text-center">
            {isEng
              ? "RELIABLE SUPPLY OF SUGAR AND AGRICULTURAL PRODUCTS FOR YOUR BUSINESS"
              : "НАДІЙНІ ПОСТАВКИ ЦУКРУ ТА АГРОПРОДУКЦІЇ ДЛЯ ВАШОГО ПІДПРИЄМСТВА"}
          </h1>
          <article className="flex flex-wrap justify-center gap-8">
            <div className="w-80 h-40 bg-th-blue flex flex-col px-8 gap-y-4 items-center justify-center rounded-tl-3xl rounded-br-3xl">
              <p className="text-white">{isEng ? "Buy" : "Купити"}</p>
              <div className="w-full h-0 border border-b border-white"></div>
              <a href="sales:info@afex.com.ua">
                <p className="text-white">sales@afex.com.ua</p>
              </a>
              <a href="tel:+380675996342">
                <p className="text-white">+380 67 599-63-42</p>
              </a>
            </div>
            <div className="w-80 h-40 bg-th-blue flex flex-col px-8 gap-y-4 items-center justify-center rounded-tl-3xl rounded-br-3xl">
              <p className="text-white">{isEng ? "Sell" : "Продати"}</p>
              <div className="w-full h-0 border border-b border-white"></div>
              <a href="zakupivli@afex.com.ua">
                <p className="text-white">zakupivli@afex.com.ua</p>
              </a>
              <a href="tel:+380683401574">
                <p className="text-white">+380 68 340-15-74</p>
              </a>
            </div>
          </article>
          <button
            className="bg-th-green py-4 px-6 text-base text-black rounded-full mx-auto"
            onClick={() => setModalOpen(!modalOpen)}
          >
            {isEng ? "Request a call" : "Замовити дзвінок"}
          </button>
          <Popup
            open={modalOpen}
            closeOnDocumentClick
            onClose={closeModal}
            className="popup-modal"
            lockScroll
          >
            <div className="modal">
              <div className="flex items-center w-full">
                <img
                  className="ml-auto w-8 cursor-pointer"
                  src={close}
                  onClick={closeModal}
                  alt="Close"
                ></img>
              </div>
              <p className="text-th-blue mt-8 font-bold">
                {isEng
                  ? "Leave us your contacts and we will call as soon as possible"
                  : "Вiдправте нам свiй номер, i ми зателефонуємо вам якнайшвидше"}
              </p>
              <section id="userform" className="">
                <form
                  onSubmit={handleSubmit(sendFormData)}
                  className="userform flex flex-col"
                >
                  <label className="mt-4">{isEng ? "Name" : "Ім’я"}</label>
                  <input
                    type="text"
                    {...register("name", { maxLength: 30, required: true })}
                  />
                  {errors.name?.type === "required" && (
                    <span className="error-span">
                      {isEng
                        ? "Please, enter your name"
                        : "Вкажіть, будь ласка, своє ім'я"}
                    </span>
                  )}
                  <label className="mt-4">
                    {isEng ? "Phone number" : "Номер телефону"}
                  </label>
                  <input
                    type="tel"
                    {...register("phone", {
                      required: true,
                      pattern:
                        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                    })}
                    aria-invalid={errors.phone ? "true" : "false"}
                  />
                  {errors.phone?.type === "required" && (
                    <span className="error-span">
                      {isEng
                        ? "Please, enter your phone number"
                        : "Будь ласка, введiть свiй номер телефону"}
                    </span>
                  )}
                  {errors.phone?.type === "pattern" && (
                    <span className="error-span">
                      {isEng
                        ? "Please, enter a correct phone number"
                        : "Будь ласка, введiть вiрний номер телефону"}
                    </span>
                  )}
                  {sendSuccess && (
                    <span className="success-span">
                      {isEng
                        ? "Your data was processed successfully! We will call you as soon as possible"
                        : "Ваш запит отримано! Ми зателефонуємо вам якнайшвидше"}
                    </span>
                  )}
                  <button
                    type="submit"
                    className="bg-th-green py-4 px-6 text-base text-black rounded-full mx-auto mt-8"
                    onClick={() => setModalOpen(!modalOpen)}
                  >
                    {isEng ? "Request a call" : "Замовити дзвінок"}
                  </button>
                </form>
              </section>
            </div>
          </Popup>
          <a
            href={
              isEng
                ? "https://drive.google.com/file/d/1oQnbCJqkaaRhC0F8wxN-UZr-oTQcWNmA/view?usp=sharing"
                : "https://drive.google.com/file/d/1T8X-hjDgeN8VYH6dRfUcec7i_RqpKsDj/view?usp=sharing"
            }
            target="_blank"
            rel="noreferrer"
          >
            <p className="text-th-blue font-bold">
              {isEng ? "Download brochure" : "Завантажити брошуру"}
            </p>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Main;
